import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const CasinoAds = lazy(() => import('../pages/casino-ads'))

type CasinoAdsSearch = {
  referral?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  bonus?: string
}

export const casinoAdsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'casinoads',
  component: CasinoAds,
  validateSearch: (search: Record<string, unknown>): CasinoAdsSearch => {
    return {
      utm_campaign: typeof search.utm_campaign === 'string' ? search.utm_campaign : '',
      utm_medium: typeof search.utm_medium === 'string' ? search.utm_medium : '',
      utm_source: typeof search.utm_source === 'string' ? search.utm_source : '',
    }
  },
})
