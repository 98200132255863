import spei from '../assets/wallet-management/spei.webp'

export const WITHDRAW_CHANNELS_EN = [
  {
    icon: spei,
    label: 'SPEI Transfer',
  },
]

export const WITHDRAW_CHANNELS_ES = [
  {
    icon: spei,
    label: 'Transferencia de SPEI',
  },
]
