import { Route, lazy } from '@tanstack/router'

import { depositRoute } from '.'

const Deposit = lazy(() => import('../../pages/deposit'))

export const depositIndexRoute = new Route({
  getParentRoute: () => depositRoute,
  path: '/',
  component: Deposit,
})
