import { useEffect } from 'react'
import { styled } from '@linaria/react'

import { useBoundStore } from '../../store/store'
import { numberWithCommas } from '../../utils/number'
import Icon from '../icon'
import useRefreshBalance from '../../hooks/useRefreshBalance'

const Container = styled.div`
  margin-bottom: 16px;
  @media (min-width: 1200px) {
    margin-bottom: 24px;
  }
`

const WalletContainer = styled.div`
  width: 22px;
  margin-right: 12px;
  svg {
    fill: var(--txt);
  }
`

const Text = styled.span`
  font-size: 16px;
  color: var(--txt);
  font-family: 'Avenir-Light';
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`

const Balance = () => {
  const { refreshBalance, isLoading } = useRefreshBalance()
  const unified_balance = useBoundStore(state => state.user.unified_balance)

  useEffect(() => {
    refreshBalance()
  }, [])

  if (isLoading) return null

  return (
    <Container className="flx-str-ctr">
      <WalletContainer>
        <Icon id="wallet" width="22" height="23" />
      </WalletContainer>
      <Text>$ {numberWithCommas(parseFloat(unified_balance))}</Text>
    </Container>
  )
}

export default Balance
