import axios from 'axios'
import { useEffect } from 'react'

import { useBoundStore } from '../store/store'
import type { SettingsSliceStates } from '../store/types/settings'
import { api } from '../utils/api-client'

const useSettings = () => {
  const setSettings = useBoundStore(state => state.settings.setSettings)
  const controller = new AbortController()
  const signal = controller.signal

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await api.get<SettingsSliceStates>(`/settings.php`, undefined, { signal })
        if (res.data) {
          setSettings(res.data)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      }
    }
    fetchSettings()

    return () => {
      controller.abort()
    }
  }, [])
}

export default useSettings
