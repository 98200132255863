import NiceModal, { useModal } from '@ebay/nice-modal-react'
import type { MakeLinkOptions } from '@tanstack/router'
import { useMatch, useNavigate, useSearch } from '@tanstack/router'
import { type ChangeEvent, type FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDimensions from '../../hooks/useDimensions'
import useGeoCheck from '../../hooks/useGeoCheck'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import type { UserInfo } from '../../types/user'
import { api } from '../../utils/api-client'
import classNames from '../../utils/classNames'
import closeModal from '../../utils/closeModal'
import { getDeviceInfo } from '../../utils/device'
import { getPublicAuth } from '../../utils/getPublicAuth'
import { encryptUser } from '../../utils/user'
import Icon from '../icon'
import { TailSpin } from '../loaders'
import ReferNowModal from '../referral-v2/refer-now-modal'
import ReferralCreditModal from '../referral-v2/referral-credit-modal'
import WalletManagementModal from '../wallet-management/wallet-management-modal'
import DuplicateAccountModal from './duplicate-account-modal'
import Modal from './modal'
import RequestPasswordResetModal from './request-password-reset-modal'
import SocialMediaAuth from './social-media-auth'

const LoginModal = () => {
  const affiliateId = (useMatch().params as { affiliate?: string }).affiliate
  const { isDesktop } = useDimensions()
  const { t, i18n } = useTranslation(['login', 'walletManagement'])
  const { geoCheck } = useGeoCheck()
  const { language } = i18n
  const { redirectUrl } = useSearch()
  const setUserStore = useBoundStore(state => state.user.setUser)
  const enableSocialMediaAuth = useBoundStore(
    state => state.settings.social_media_auth,
  )
  const modal = useModal()
  const requestPasswordResetModal = useModal(RequestPasswordResetModal)
  const walletManagementModal = useModal(WalletManagementModal)
  const duplicateModal = useModal(DuplicateAccountModal)
  const referralCreditModal = useModal(ReferralCreditModal)
  const referNowModal = useModal(ReferNowModal)
  const navigate = useNavigate()
  const [user, setUser] = useState({
    username: '',
    password: '',
  })
  const [isRemember, setIsRemember] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [hidePassword, setHidePassword] = useState(true)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSuccess = async (info: UserInfo, status: number) => {
    if (status === 2) {
      kwaiq.instance('488334558124585026').track('completeRegistration')
    }
    if (
      location.pathname.includes('/sorteos') ||
      location.pathname.includes('/sports')
    ) {
      location.reload()
    } else {
      if (redirectUrl) {
        navigate({ to: redirectUrl, search: {} } as MakeLinkOptions<'/'>)
        await closeModal(modal)
        return
      }

      if (isDesktop) {
        walletManagementModal.show({})
        location.pathname.includes('/register') && navigate({ to: '/' })
      } else {
        navigate({ to: '/deposit' })
      }
      if (info.coin_drop === 1) {
        referralCreditModal.show()
      } else {
        referNowModal.show()
      }
    }

    await closeModal(modal)
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!user.username || !user.password) return

    setError('')
    setIsLoading(true)

    const publicAuth = await getPublicAuth()

    const reqBody = {
      ...user,
      username: user.username.toLowerCase(),
      submit_type: 'login',
      meta: getDeviceInfo(),
      auth: publicAuth,
    }

    const response = await api.post<ApiResponse<UserInfo>>(
      '/center.php',
      reqBody,
    )

    const { status, info } = response.data

    if (status === 1) {
      const privateAuth = await api.post<
        ApiResponse<string | { auth: string; expired_time: number }>
      >('/link.php', {
        username: info.username,
        password: user.password,
        auth: publicAuth,
      })
      if (
        privateAuth.data.status === 1 &&
        typeof privateAuth.data.info !== 'string'
      ) {
        localStorage.setItem('privateAuth', privateAuth.data.info.auth)
      } else {
        if (typeof privateAuth.data.info === 'string') {
          setError(privateAuth.data.info)
        }
        setIsLoading(false)
        return
      }
      encryptUser({ username: info.username, password: user.password })
      setUserStore(info)
      onSuccess(info, 1)
    } else if (status === 2) {
      if (typeof info === 'string') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        navigate({ to: info })
        await closeModal(modal)
      }
    } else if (status === 3) {
      duplicateModal.show()
      await closeModal(modal)
    } else {
      if (typeof info === 'string') setError(info as unknown as string)
    }
    setIsLoading(false)
  }

  return (
    <Modal
      id="log-in-modal"
      containerStyle="bg-white w-[calc(100%_-_32px)] !p-5 rounded-[10px]">
      <button className="w-full flex mb-6">
        <img
          className="w-full rounded-[10px]"
          src={`https://www.u2d8899.com/mexicoimages/banner/login_banner_${language}.webp`}
        />
      </button>
      <form onSubmit={onSubmit}>
        {error && (
          <p className="text-sm text-[rgb(255,64,88)] w-full mb-2.5 px-0 py-0.5 xl:text-base">
            {error}
          </p>
        )}
        <label className="w-full block uppercase text-sm font-Syncopate-Bold text-[#3b3e49] mb-1">
          {t('username-or-email')}
        </label>
        <input
          className="text-[#3b3e49] text-base rounded w-full border transition-all duration-[0.2s] ease-[ease-in-out] mb-3.5 px-2.5 py-2 border-solid border-[#3b3e49]"
          name="username"
          onChange={onChange}
        />
        <label className="w-full block uppercase text-sm font-Syncopate-Bold text-[#3b3e49] mb-1">
          {t('password')}
        </label>
        <div className="flx-ctr" style={{ position: 'relative' }}>
          <input
            className="text-[#3b3e49] text-base rounded w-full pr-[30px] border transition-all duration-[0.2s] ease-[ease-in-out] mb-3.5 px-2.5 py-2 border-solid border-[#3b3e49]"
            name="password"
            type={hidePassword ? 'password' : 'text'}
            onChange={onChange}
          />
          <div
            className="absolute top-[calc(50%_-_5px)] -translate-y-2/4 flex cursor-pointer right-2"
            onClick={() => setHidePassword(prev => (prev ? false : true))}>
            {hidePassword ? (
              <Icon id="eye-slash" width="20" height="20" stroke="#888" />
            ) : (
              <Icon id="eye" width="20" height="20" stroke="#0e091e" />
            )}
          </div>
        </div>
        <label
          className={classNames(
            'relative text-xs font-Syncopate-Bold inline-block transition-all duration-[0.2s] ease-[ease-in-out] uppercase mb-4 pl-7',
            isRemember ? 'text-[#3b3e49]' : 'text-[#aaa]',
          )}>
          {t('remember-me')}
          <input
            className="hidden"
            type="checkbox"
            checked={isRemember}
            onChange={e => setIsRemember(e.target.checked)}
          />
          <span
            className={classNames(
              `inline-block w-[18px] h-[18px] border absolute -translate-y-2/4 transition-all duration-[0.2s] ease-[ease-in-out] p-[3px] rounded-sm border-solid left-0 top-2/4 after:content-[''] after:absolute after:bg-[#3b3e49] after:transition-all after:duration-[0.2s] after:ease-[ease-in-out] after:w-2.5 after:h-2.5 after:rounded-sm after:left-[3px] after:top-[3px]`,
              isRemember
                ? 'border-[#3b3e49] after:opacity-100'
                : 'border-[#aaa] after:opacity-0',
            )}
          />
        </label>
        <p
          className="text-xs font-Syncopate-Bold text-center uppercase mb-4 text-[#848292] cursor-pointer"
          onClick={async () => {
            requestPasswordResetModal.show()
            await closeModal(modal)
          }}>
          <span
            className="uppercase font-Syncopate-Bold"
            style={{ textDecoration: 'underline' }}>
            {t('forgot-password')}
          </span>
        </p>
        <button
          type="button"
          style={{ width: '100%' }}
          onClick={async () => {
            geoCheck(() =>
              navigate({
                to: '/register',
                search:
                  typeof affiliateId === 'string'
                    ? {
                        utm_source: 'affiliates',
                        utm_medium: 'influencers',
                        utm_campaign: affiliateId,
                      }
                    : undefined,
              }),
            )
            await closeModal(modal)
          }}>
          <p className="text-xs font-Syncopate-Bold text-[#1c112b] text-center uppercase mb-4">
            {t('no-account')}
          </p>
        </button>
        <div className="flx-ctr">
          {isLoading ? (
            <TailSpin width={39} height={39} color="#0e091e" />
          ) : (
            <button
              className="w-full h-[50px] text-center text-sm font-Syncopate-Bold text-[white] bg-[#0e091e] rounded transition-all duration-[0.2s] ease-[ease-in-out] uppercase cursor-pointer border-[1.5px] border-solid border-[#0e091e]"
              style={{ margin: '0 auto' }}
              type="submit">
              {t('login')}
            </button>
          )}
        </div>
      </form>
      {enableSocialMediaAuth.value === 1 && (
        <SocialMediaAuth
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setError={message => setError(message)}
          callback={onSuccess}
        />
      )}
    </Modal>
  )
}

export default NiceModal.create(LoginModal)
