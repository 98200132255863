export function generateRandomUsername(): string {
  const a = ['Small', 'Blue', 'Ugly']
  const b = ['Bear', 'Dog', 'Banana']

  const rA = Math.floor(Math.random() * a.length)
  const rB = Math.floor(Math.random() * b.length)
  return a[rA] + b[rB]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<Params extends any[]>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
