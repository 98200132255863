import { useModal } from '@ebay/nice-modal-react'
import {
  PropsWithChildren,
  SetStateAction,
  useState,
  type ChangeEventHandler,
  type Dispatch,
} from 'react'
import { useTranslation } from 'react-i18next'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { styled } from '@linaria/react'

import useDimensions from '../../hooks/useDimensions'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import MessageModal from '../common/message-modal'
import { TailSpin } from '../loaders'
import ConfirmButton from '../wallet-management/confirm-button'
import EmailVerification from './email-verification'
import SecurityNote from './security-note'

const StyledBottomSheet = styled(BottomSheet)`
  div[data-rsbs-overlay='true'] {
    background: #2e3748;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  [data-rsbs-header]:before {
    background-color: #202632;
  }
`

const Content = styled.div`
  padding: 0 12px 16px;
`

const Title = styled.p`
  font-family: 'Avenir-Heavy';
  color: #fff;
  font-size: 14px;
  padding-left: 12px;
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 18px;
    margin-bottom: 0px;
    padding-left: 0px;
  }
`

const Form = styled.div`
  padding: 12px;
  background-color: #38404f;
  margin-bottom: 16px;
  @media (min-width: 1200px) {
    background-color: transparent;
    padding: 12px 0 0;
  }
`

const FormHeader = styled.div`
  margin-bottom: 16px;
`

const FormInput = styled.div`
  position: relative;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 12px;
  }
  input {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #999999;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    color: #fff;
    &:focus {
      border-color: #fff;
    }
  }
  label {
    position: absolute;
    padding: 0 7px;
    background-color: #38404f;
    top: -9px;
    font-size: 12px;
    left: 10px;
    color: #999;
    transition: all 0.2s ease-in-out;
  }
  input:focus + label {
    color: #fff;
  }
  @media (min-width: 1200px) {
    &:last-child {
      margin-bottom: 0px;
    }
    label {
      font-size: 14px;
      background-color: #2e3748;
    }
    input {
      padding: 14px 10px;
    }
  }
`

const Checkbox = styled.label`
  position: relative;
  color: white;
  font-size: 14px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  height: max-content;
  input {
    display: none;
  }
  input:checked ~ span:after {
    opacity: 1;
  }
  span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #999999;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 4px;
    border-radius: 50%;
    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      background-color: #df0033;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const Message = styled.p`
  font-size: 14px;
  margin-bottom: 12px;
  &.error {
    color: rgb(255, 64, 88);
  }
  &.success {
    color: #4bca81;
  }
`

const LoadingContainer = styled.div`
  height: 45px;
`

interface AddUsdtProps {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  getWalletList: () => Promise<void>
}

const Wrapper = ({
  children,
  isVisible,
  setIsVisible,
}: PropsWithChildren<Pick<AddUsdtProps, 'isVisible' | 'setIsVisible'>>) => {
  const { isDesktop } = useDimensions()

  if (isDesktop) return <div>{children}</div>

  return (
    <StyledBottomSheet
      open={isVisible}
      onDismiss={() => setIsVisible(false)}
      scrollLocking={false}
      maxHeight={window.innerHeight - 100}>
      {children}
    </StyledBottomSheet>
  )
}

const AddUsdt = ({ isVisible, setIsVisible, getWalletList }: AddUsdtProps) => {
  const first_name = useBoundStore(state => state.user.first_name)
  const last_name = useBoundStore(state => state.user.last_name)
  const messageModal = useModal(MessageModal)
  const { t } = useTranslation('walletManagement')
  const { isDesktop } = useDimensions()
  const [cryptoDetails, setCryptoDetails] = useState<{
    bank_type: 'USDTTRC20' | 'USDTERC20'
    bank_no: string
    remark: string
  }>({
    bank_type: 'USDTTRC20',
    bank_no: '',
    remark: '',
  })
  const [message, setMessage] = useState<null | {
    message: string
    isValid: boolean
  }>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    setCryptoDetails(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onAdd = async () => {
    setMessage(null)
    const { bank_type, bank_no, remark } = cryptoDetails
    const reqBody = {
      submit_type: 'bindcard',
      bank_type: bank_type,
      bank_city: '',
      realname: first_name && last_name ? `${first_name} ${last_name}` : '',
      bank_no: bank_no,
      bank_addr: remark,
      bank_province: '',
    }

    setIsLoading(true)
    const response = await api.post<ApiResponse<string>>('/center.php', reqBody)
    const { status, info } = response.data
    if (status === 1) {
      await getWalletList()
      setIsVisible(false)
      messageModal.show({
        title: `${t('successfully-added')}`,
        message: '',
      })
    } else {
      setMessage({ message: info, isValid: false })
    }
    setIsLoading(false)
  }

  const renderActionButtons = () => {
    if (isDesktop)
      return (
        <div className="flx-ctr" style={{ gap: '10px', marginTop: '12px' }}>
          <ConfirmButton
            className="secondary"
            onClick={() => setIsVisible(false)}>
            {t('cancel')}
          </ConfirmButton>
          {isLoading ? (
            <LoadingContainer className="flx-ctr" style={{ width: '100%' }}>
              <TailSpin color="#fff" width={40} height={40} />
            </LoadingContainer>
          ) : (
            <ConfirmButton onClick={onAdd}>{t('add')}</ConfirmButton>
          )}
        </div>
      )

    return (
      <>
        {isLoading ? (
          <LoadingContainer className="flx-ctr">
            <TailSpin color="#fff" width={40} height={40} />
          </LoadingContainer>
        ) : (
          <ConfirmButton style={{ marginTop: '12px' }} onClick={onAdd}>
            {t('add')}
          </ConfirmButton>
        )}
      </>
    )
  }

  return (
    <Wrapper isVisible={isVisible} setIsVisible={setIsVisible}>
      <Content>
        <Title>{t('add-usdt-address')}</Title>
        <Form>
          <FormHeader className="flx-str-ctr">
            <Checkbox>
              TRC20
              <input
                type="radio"
                name="crypto"
                value="USDTTRC20"
                checked={cryptoDetails.bank_type === 'USDTTRC20'}
                onClick={() =>
                  setCryptoDetails(prev => ({
                    ...prev,
                    bank_type: 'USDTTRC20',
                  }))
                }
              />
              <span />
            </Checkbox>
            <Checkbox>
              ERC20
              <input
                type="radio"
                name="crypto"
                value="USDTERC20"
                checked={cryptoDetails.bank_type === 'USDTERC20'}
                onClick={() =>
                  setCryptoDetails(prev => ({
                    ...prev,
                    bank_type: 'USDTERC20',
                  }))
                }
              />
              <span />
            </Checkbox>
          </FormHeader>
          <FormInput>
            <input
              name="bank_no"
              value={cryptoDetails.bank_no}
              onChange={onChange}
            />
            <label>{t('address')}</label>
          </FormInput>
          <FormInput>
            <input
              name="remark"
              value={cryptoDetails.remark}
              onChange={onChange}
            />
            <label>{t('remark')}</label>
          </FormInput>
          {message && (
            <Message className={message.isValid ? 'success' : 'error'}>
              {message.message}
            </Message>
          )}
        </Form>
        <SecurityNote />
        <EmailVerification isVisible={isVisible} />
        {renderActionButtons()}
      </Content>
    </Wrapper>
  )
}

export default AddUsdt
