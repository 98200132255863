import type { RegisterSearch } from "../routes/register"

export const getFbp = () => {
  const name = '_fbp='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return ''
}

export const getAdsInfo = (): RegisterSearch => {
  try {
    const adsInfo = JSON.parse(
      localStorage.getItem('ads_info') ?? '{}',
    ) as RegisterSearch
    return adsInfo
  } catch (error) {
    return {}
  }
}
