import { Route, lazy } from '@tanstack/router'

import { promotionsRoute } from '.'

const Promotions = lazy(() => import('../../pages/promotions'))

export const promotionsIndexRoute = new Route({
  getParentRoute: () => promotionsRoute,
  path: '/',
  component: Promotions,
})
