import { Outlet, Route } from '@tanstack/router'

import protectRoute from '../../hoc/protectRoute'
import { rootRoute } from '../root'

export const depositRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'deposit',
  component: protectRoute(Outlet, true),
})
