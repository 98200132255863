import { CSSProperties } from 'react'
import { styled } from '@linaria/react'

import { useBoundStore } from '../../store/store'
import { TailSpin } from '../loaders'

const Container = styled.div`
  width: 100%;
`

interface SpinnerProps {
  containerStyle?: CSSProperties
  spinnerSize?: number
}

const Spinner = ({ containerStyle, spinnerSize }: SpinnerProps) => {
  const theme = useBoundStore(state => state.common.theme)

  return (
    <Container className="flx-ctr" style={containerStyle}>
      <TailSpin
        color={theme === 'dark' ? '#fff' : '#0E091E'}
        width={spinnerSize ?? 40}
        height={spinnerSize ?? 40}
      />
    </Container>
  )
}

export default Spinner
