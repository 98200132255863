import { styled } from '@linaria/react'
import axios from 'axios'
import type { ReactNode } from 'react'
import { type Dispatch, type SetStateAction,useEffect, useState } from 'react'

import type { ApiResponse } from '../../types/api'
import type { WithdrawTypes } from '../../types/wallet-management'
import { api } from '../../utils/api-client'
import Icon from '../icon'

const Container = styled.div`
  margin-bottom: 20px;
  &.usdt,
  &.error {
    margin-bottom: 12px;
  }
  @media (min-width: 1200px) {
    margin-bottom: 30px;
  }
`

const Button = styled.button`
  width: 45px;
  height: 45px;
  background-color: var(--tertiary);
  border-radius: 4px;
  font-size: 30px;
  color: var(--txt);
  cursor: pointer;

  svg {
    fill: var(--txt);
  }
  @media (min-width: 1200px) {
    width: 55px;
    height: 55px;
    svg {
      width: 18px;
    }
  }
`

const Input = styled.input`
  font-size: 18px;
  color: var(--txt);
  background-color: var(--tertiary);
  border-radius: 4px;
  text-align: center;
  flex: 1;
  height: 45px;
  min-width: 0;
  margin: 0 15px;
  font-family: 'Avenir-Light';
  @media (min-width: 1200px) {
    font-size: 26px;
    height: 55px;
  }
`

const Text = styled.p`
  font-family: 'Avenir-Heavy';
  font-size: 16px;
  color: var(--txt);
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 18px;
    margin-bottom: 16px;
  }
`

const Usdt = styled.p`
  color: var(--txt);
  font-family: 'Avenir-Light';
  font-size: 12px;
  margin-bottom: 20px;
  padding-left: 60px;
  span {
    font-family: 'Syncopate-Bold';
    text-transform: uppercase;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    margin-bottom: 30px;
    padding-left: 75px;
  }
`

const ErrorMessage = styled.p`
  font-size: 14px;
  color: #df0033;
  font-family: 'Avenir-Light';
  margin-bottom: 20px;
  @media (min-width: 1200px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
`

export interface AmountInputProps {
  amount: number
  setAmount: Dispatch<SetStateAction<number>>
  page: string
  type?: WithdrawTypes
  tooltip?: ReactNode
  error?: string
}

const AmountInput = ({
  amount,
  tooltip,
  setAmount,
  page,
  type,
  error,
}: AmountInputProps) => {
  const [conversion, setConversion] = useState(0)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const fetchConversion = async () => {
      try {
        const response = await api.post<ApiResponse<number>>('/request.php', {
          type: 'convert_currency',
        }, { signal })
        const { status, info } = response.data
        if (status === 1) {
          setConversion(info)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      }
    }
    fetchConversion()

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <Text className='flx-str-ctr'>{page} {tooltip && tooltip}</Text>
      <Container className={`flx-ctr ${type} ${error ? 'error' : ''}`}>
        <Button
          className="flx-ctr"
          onClick={() =>
            setAmount(prev => (prev - 50 >= 0 ? prev - 50 : prev))
          }>
          <Icon id="minus" width="16" />
        </Button>
        <Input
          type="number"
          inputMode="numeric"
          value={amount}
          onChange={e => setAmount(parseFloat(e.target.value))}
        />
        <Button
          className="flx-ctr"
          onClick={() => setAmount(prev => prev + 50)}>
          <Icon id="plus" width="16" />
        </Button>
      </Container>
      {type === 'usdt' && (
        <Usdt>
          <span>USDT = </span>
          {(amount * conversion).toFixed(2)}
        </Usdt>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  )
}

export default AmountInput
