import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useNavigate } from '@tanstack/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

import useGeoCheck from '../../hooks/useGeoCheck'
import type { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import closeModal from '../../utils/closeModal'
import { TailSpin } from '../loaders'
import Modal from './modal'

const Title = styled.p`
  font-size: 15px;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  color: var(--txt);
  text-align: center;
  margin-bottom: 25px;
`

const Text = styled.p`
  font-size: 15px;
  color: var(--txt);
  margin-bottom: 5px;
  a,
  button {
    color: var(--txt);
  }
`

const StyledInput = styled.input`
  color: var(--txt);
  font-size: 16px;
  border-radius: 0px;
  padding: 7px 0px;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--txt);
  transition: all 0.2s ease-in-out;
  .theme-dark &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .theme-light &::placeholder {
    color: #ccc;
  }
`

const Button = styled.button`
  text-align: center;
  font-size: 15px;
  padding: 7px 24px;
  background-color: var(--ok-btn-bg);
  border: 1.5px solid var(--ok-btn-bg);
  color: var(--ok-btn-txt);
  border-radius: 22px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-family: 'Avenir-Light';
  margin: 0 auto;
  display: block;
`

const Message = styled.p`
  font-size: 15px;
  margin-bottom: 10px;
  &.error {
    color: rgb(255, 64, 88);
  }
  &.success {
    color: #4bca81;
  }
`

const RequestPasswordResetModal = () => {
  const { t } = useTranslation(['login', 'recoverPassword', 'register'])
  const { geoCheck } = useGeoCheck()
  const navigate = useNavigate()
  const modal = useModal()
  const [account, setAccount] = useState('')
  const [message, setMessage] = useState<{
    message: string
    isValid: boolean
  } | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onSend = async () => {
    if (!account)
      return setMessage({
        message: t('username-required', { ns: 'register' }),
        isValid: false,
      })

    setIsLoading(true)
    const reqBody = {
      type: 'send_reset_password',
      account,
    }
    const response = await api.post<ApiResponse<string>>(
      '/request.php',
      reqBody,
    )
    const { info, status } = response.data
    setMessage({ message: info, isValid: status === 1 ? true : false })

    setIsLoading(false)
  }

  return (
    <Modal id="request-password-reset-modal">
      <Title>{t('title', { ns: 'recoverPassword' })}</Title>
      {message && (
        <Message className={message.isValid ? 'success' : 'error'}>
          {message.message}
        </Message>
      )}
      <Text>{t('enter-email', { ns: 'recoverPassword' })}</Text>
      <StyledInput
        name="email"
        placeholder={t('username-or-email', { ns: 'login' })}
        value={account}
        onChange={e => setAccount(e.target.value)}
      />
      <div className="flx-ctr" style={{ margin: '20px 0' }}>
        {isLoading ? (
          <TailSpin width={39} height={39} color="white" />
        ) : (
          <Button onClick={onSend}>
            {t('send-request', { ns: 'recoverPassword' })}
          </Button>
        )}
      </div>
      <Text style={{ textAlign: 'center' }}>
        {t('opened', { ns: 'recoverPassword' })}{' '}
        <button
          onClick={() => {
            geoCheck(() => navigate({ to: '/register' }))
            closeModal(modal)
          }}
          style={{ textDecoration: 'underline' }}>
          {t('join-now', { ns: 'recoverPassword' })}
        </button>
      </Text>
    </Modal>
  )
}

export default NiceModal.create(RequestPasswordResetModal)
