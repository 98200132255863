import { useEffect } from 'react'

import { getAdsInfo, getFbp } from '../utils/ads-info'

function useAdsInfo() {
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    const adsInfo = getAdsInfo()
    const search = Object.fromEntries(params.entries())
    const fbp = getFbp()

    if (fbp) {
      search.fbp = fbp
    }

    const latestAdsInfo = { ...adsInfo, ...search }

    if (Object.keys(latestAdsInfo).length) {
      localStorage.setItem(
        'ads_info',
        JSON.stringify(latestAdsInfo),
      )
    }
  }, [])
}

export default useAdsInfo
