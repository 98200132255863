import { useMatches } from '@tanstack/router'
import { useEffect, useState } from 'react'

const useKwaiq = () => {
  const [isLoading, setIsLoading] = useState(true)
  const matches = useMatches()
  const routeId = matches.slice(-1)[0].route.id as string

  useEffect(() => {
    setIsLoading(true)
    const onPageLoad = () => {
      setIsLoading(false)
    }
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad, false)
    }

    return () => {
      window.removeEventListener('load', onPageLoad)
    }
  }, [routeId])

  useEffect(() => {
    if (isLoading) return

    const btns = document.querySelectorAll('button')
    btns.forEach(btn => {
      btn.addEventListener('click', () => {
        kwaiq.instance('488334558124585026').track('buttonClick')
      })
    })

    return () => {
      btns.forEach(btn => {
        btn.removeEventListener('click', () => {
          kwaiq.instance('488334558124585026').track('buttonClick')
        })
      })
    }
  }, [isLoading])

  useEffect(() => {
    kwaiq.instance('488334558124585026').page()
  }, [routeId])
}

export default useKwaiq
