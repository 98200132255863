import { Route, lazy } from '@tanstack/router'

import { profileRoute } from '.'

const FreeBonus = lazy(() => import('../../pages/profile/free-bonus'))

export const freeBonusRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'free-bonus',
  component: FreeBonus,
})
