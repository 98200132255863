import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import axios from 'axios'
import {
  type ChangeEventHandler,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import spei from '../../assets/wallet-management/spei.webp'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import type { BankCodeObj } from '../../types/wallet-management'
import { api } from '../../utils/api-client'
import MessageModal from '../common/message-modal'
import ScrollSelector, { type Item } from '../common/scroll-selector'
import { TailSpin } from '../loaders'
import ConfirmButton from '../wallet-management/confirm-button'
import SecurityNote from './security-note'

const Content = styled.div`
  padding: 0 12px 16px;
  @media (min-width: 1200px) {
    padding: 0;
  }
`

const Title = styled.p`
  font-family: 'Avenir-Black';
  color: var(--txt);
  font-size: 14px;
  padding-left: 12px;
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 18px;
    margin-bottom: 0px;
    padding-left: 0px;
  }
`

const Form = styled.div`
  padding: 12px;
  background-color: var(--tertiary);
  margin-bottom: 16px;
  @media (min-width: 1200px) {
    background-color: transparent;
    padding: 12px 0 0;
  }
`

const CardImages = styled.div`
  gap: 7px;
`

const FormHeader = styled.div`
  margin-bottom: 16px;
  justify-content: flex-end;
`

const FormInput = styled.div`
  position: relative;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 12px;
  }
  input {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #999999;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir-Light';
    color: var(--txt);
    &:focus {
      border-color: var(--txt);
    }
  }
  label {
    position: absolute;
    padding: 0 7px;
    background-color: var(--tertiary);
    top: -9px;
    font-size: 12px;
    left: 10px;
    color: #999;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir-Light';
  }
  input:focus + label {
    color: var(--txt);
  }
  @media (min-width: 1200px) {
    &:last-child {
      margin-bottom: 0px;
    }
    label {
      font-size: 14px;
      background-color: var(--secondary);
    }
    input {
      padding: 14px 10px;
    }
  }
`

const Message = styled.p`
  font-family: 'Avenir-Light';
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  &.error {
    color: rgb(255, 64, 88);
  }
  &.success {
    color: #4bca81;
  }
`

interface AddBankProps {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  getWalletList: () => Promise<void>
}

interface BankDetails {
  realname: string
  email: string
  bank_no: number | undefined
}

const AddBank = ({ isVisible, setIsVisible, getWalletList }: AddBankProps) => {
  const email = useBoundStore(state => state.user.email)
  const first_name = useBoundStore(state => state.user.first_name)
  const last_name = useBoundStore(state => state.user.last_name)
  const messageModal = useModal(MessageModal)
  const scrollSelectorModal = useModal(ScrollSelector)
  const { t } = useTranslation(['register', 'walletManagement'])
  const [bankDetails, setBankDetails] = useState<BankDetails>({
    realname: first_name && last_name ? `${first_name} ${last_name}` : '',
    bank_no: undefined,
    email,
  })
  const [message, setMessage] = useState<null | {
    message: string
    isValid: boolean
  }>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [bankList, setBankList] = useState<Item[]>([])
  const [selectedBankCode, setSelectedBankCode] = useState<Item | null>(null)

  useEffect(() => {
    if (!isVisible) return
    const controller = new AbortController()
    const signal = controller.signal

    const fetchBankList = async () => {
      try {
        setIsLoading(true)

        const response = await api.post<ApiResponse<BankCodeObj[]>>(
          '/payment.php',
          {
            type: 'withdraw_bank_list',
          },
          { signal }
        )
        const { status, info } = response.data
        if (status === 1) {
          setBankList(
            info.map(bank => ({
              label: `${bank.bank_name} - ${bank.bank_code}`,
              id: bank.bank_code,
            })),
          )
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchBankList()

    return () => {
      controller.abort()
    }
  }, [isVisible])

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.name === 'bank_no' && e.target.value.length > 18) return
    setBankDetails(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onAdd = async () => {
    setMessage(null)

    if (!selectedBankCode) return

    const { realname, email, bank_no } = bankDetails
    const reqBody = {
      submit_type: 'bindcard',
      bank_type: selectedBankCode.id,
      realname: realname,
      bank_no: bank_no,
      bank_addr: email,
    }

    setIsLoading(true)
    const response = await api.post<ApiResponse<string>>('/center.php', reqBody)
    const { status, info } = response.data
    if (status === 1) {
      await getWalletList()
      setIsVisible(false)
      messageModal.show({
        title: t('successfully-added', { ns: 'walletManagement' }),
        message: '',
      })
    } else {
      setMessage({ message: info, isValid: false })
    }
    setIsLoading(false)
  }

  const showSelectBank = () => {
    scrollSelectorModal.show({
      items: bankList,
      selected: selectedBankCode,
      onOk: (item: Item) => setSelectedBankCode(item),
    })
  }

  return (
    <Content>
      <Title>{t('add-bank-account-details', { ns: 'walletManagement' })}</Title>
      <Form>
        {message && (
          <Message
            className={message.isValid ? 'success' : 'error'}
            style={{ marginTop: 0, textAlign: 'left' }}>
            {message.message}
          </Message>
        )}
        <FormHeader className="flx-btw-ctr">
          <CardImages className="flx-ctr">
            <img src={spei} style={{ width: '42px' }} />
          </CardImages>
        </FormHeader>
        <FormInput>
          <input
            value={bankDetails.realname}
            name="realname"
            onChange={onChange}
          />
          <label>{t('name-and-surname', { ns: 'walletManagement' })}</label>
        </FormInput>
        <FormInput>
          <input value={bankDetails.email} name="email" onChange={onChange} />
          <label>{t('email', { ns: 'register' })}</label>
        </FormInput>
        <FormInput>
          <input
            value={selectedBankCode?.label}
            name="bank_type"
            onClick={showSelectBank}
            readOnly
          />
          <label>{t('bank-code', { ns: 'walletManagement' })}</label>
        </FormInput>
        <FormInput>
          <input
            type="number"
            inputMode="numeric"
            value={bankDetails.bank_no}
            name="bank_no"
            onChange={onChange}
          />
          <label>CLABE</label>
        </FormInput>
      </Form>
      <SecurityNote />
      <div className="flx-ctr" style={{ gap: '10px', marginTop: '12px' }}>
        <ConfirmButton
          className="secondary"
          onClick={() => setIsVisible(false)}>
          {t('cancel', { ns: 'walletManagement' })}
        </ConfirmButton>
        <ConfirmButton
          onClick={() => {
            if (bankDetails.bank_no?.toString().length !== 18) {
              setMessage({
                message: t('clabe-18', { ns: 'walletManagement' }),
                isValid: false,
              })
              return
            }
            onAdd()
          }}
          disabled={
            isLoading ||
            !selectedBankCode ||
            Object.keys(bankDetails).some(
              key => !bankDetails[key as keyof BankDetails],
            )
          }>
          {bankList.length > 0 && isLoading ? (
            <TailSpin width={25} height={25} />
          ) : (
            t('add', { ns: 'walletManagement' })
          )}
        </ConfirmButton>
      </div>
    </Content>
  )
}

export default AddBank
