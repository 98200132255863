import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'
import Icon from '../icon'

const Container = styled.div`
  width: 1100px;
  display: flex;
`

const Tab = styled.button`
  flex: 1;
  height: 60px;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  font-size: 18px;
  color: #999;
  transition: all 0.2s ease-in-out;
  .theme-dark & {
    background-color: #1e1629;
  }
  .theme-light & {
    background-color: #e0e0ec;
  }
  svg {
    margin-right: 12px;
    transition: all 0.2s ease-in-out;
    fill: #999;
  }
  &.active {
    color: var(--txt);
    background-color: transparent;
    svg {
      fill: var(--txt);
    }
  }
`

interface TabsProps {
  activeTab: number
  setActiveTab: Dispatch<SetStateAction<number>>
}

const Tabs = ({ activeTab, setActiveTab }: TabsProps) => {
  const { t } = useTranslation('walletManagement')

  return (
    <Container>
      <Tab
        className={`flx-ctr ${activeTab === 0 ? 'active' : ''}`}
        onClick={() => setActiveTab(0)}>
        <Icon id="deposit-tab" width="28" />
        {t('deposit')}
      </Tab>
      <Tab
        className={`flx-ctr ${activeTab === 1 ? 'active' : ''}`}
        onClick={() => setActiveTab(1)}>
        <Icon id="withdraw-tab" width="35" />
        {t('withdrawal')}
      </Tab>
    </Container>
  )
}

export default Tabs
