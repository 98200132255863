import type { AxiosError, AxiosRequestConfig } from 'axios'

class FetchServerError extends Error {
  request: AxiosRequestConfig | undefined
  response: AxiosError | undefined
  body: string | undefined

  constructor(
    message: string,
    request: AxiosRequestConfig<unknown>,
    response: AxiosError,
    body: string,
  ) {
    super(message)
    this.name = 'FetchServerError'
    if (request) this.request = request
    if (response) this.response = response
    if (body) this.body = body
  }
}

class FetchClientError extends Error {
  request: AxiosRequestConfig | undefined
  response: AxiosError | undefined
  body: string | undefined

  constructor(
    message: string,
    request: AxiosRequestConfig,
    response: AxiosError,
    body: string,
  ) {
    super(message)
    this.name = 'FetchClientError'
    if (request) this.request = request
    if (response) this.response = response
    if (body) this.body = body
  }
}

export function handleFetchError<RequestData>(
  response: AxiosError,
  request: AxiosRequestConfig<RequestData>,
) {
  if (response.response && response.response?.status !== 200) {
    if (response.response.status >= 400 && response.response.status < 500) {
      throw new FetchClientError(
        response.response.statusText,
        request,
        response,
        response.message,
      )
    } else {
      throw new FetchServerError(
        response.response.statusText,
        request,
        response,
        response.message,
      )
    }
  }
}
