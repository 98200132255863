import { useModal } from '@ebay/nice-modal-react'
import { type PropsWithChildren, useEffect } from 'react'
import { Portal } from 'react-portal'

import classNames from '../../utils/classNames'
import closeModal from '../../utils/closeModal'
import Backdrop from './backdrop'

type ModalProps = PropsWithChildren<{
  id: string
  containerStyle?: string
  zIndex?: number
  onModalClose?: () => void
  disableOverlayClose?: boolean
}>

const Modal = ({
  children,
  containerStyle = '',
  id,
  zIndex,
  onModalClose,
  disableOverlayClose,
}: ModalProps) => {
  const modal = useModal()

  const isVisible = modal.visible

  useEffect(() => {
    isVisible
      ? document.body.classList.add('noscroll')
      : document.body.classList.remove('noscroll')
  }, [isVisible])

  return (
    <Portal>
      <Backdrop
        id={id}
        isVisible={isVisible}
        onClick={() => {
          onModalClose?.()
          !disableOverlayClose && closeModal(modal)
        }}
        zIndex={zIndex ?? 203}
      />
      <div
        className={classNames(
          'fixed w-[calc(100%_-_60px)] max-w-[400px] transition-all duration-[0.3s] ease-[ease-in-out] animate-[fadeIn_0.3s_ease-in-out]',
          'bg-primary pt-[35px] pb-[30px] px-5 rounded-[20px] border-2 border-solid border-tertiary left-2/4 top-2/4',
          containerStyle,
        )}
        style={{
          zIndex: zIndex ? zIndex + 1 : 204,
          transform: isVisible
            ? 'translate(-50%, -50%)'
            : 'translate(-50%, calc(-50% - 50px))',
          opacity: isVisible ? 1 : 0,
          pointerEvents: isVisible ? 'auto' : 'none',
        }}>
        {children}
      </div>
    </Portal>
  )
}

export default Modal
