import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Portal } from 'react-portal'
import { styled } from '@linaria/react'

import closeModal from '../../utils/closeModal'
import Icon from '../icon'
import app_logo from '../../assets/common/app_logo.webp'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary);
  border-radius: 6px;
  padding: 12px 31px 12px 14px;
  width: 320px;
  z-index: 20;
  opacity: 0;
  bottom: 0px;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  gap: 12px;
  @keyframes popup {
    0% {
      bottom: 0px;
      opacity: 0;
    }
    100% {
      bottom: 70px;
      opacity: 1;
    }
  }
  .theme-dark & {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }
  .theme-light & {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  &.active {
    bottom: 70px;
    opacity: 1;
    pointer-events: auto;
    animation: popup 0.2s ease-in-out;
  }
`

const DownloadBtn = styled.a`
  background-color: #3dc982;
  font-family: 'Avenir-Heavy';
  color: #fff;
  font-size: 14px;
  padding: 7px 12px;
  border-radius: 4px;
`

const Close = styled.button`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  cursor: pointer;
  * {
    pointer-events: none;
  }
  svg {
    fill: var(--txt);
  }
`

const AppLogo = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 4px;
  margin-right: 16px;
`

const AppDescription = styled.div`
  .name {
    font-family: 'Avenir-Heavy';
    font-size: 16px;
    color: var(--txt);
  }
  .desc {
    font-size: 12px;
    color: var(--secondary-txt);
  }
`

const IosDownloadBanner = () => {
  const modal = useModal()
  const { t } = useTranslation('common')

  useEffect(() => {
    if (location.pathname !== '/') closeModal(modal)
  }, [location.pathname])

  return (
    <Portal>
      <Container className={`flx-btw-ctr ${modal.visible && 'active'}`}>
        <Close id="pwa-icon-close" onClick={() => closeModal(modal)}>
          <Icon id="close" width="10" height="10" />
        </Close>
        <div className='flx-ctr'>
          <AppLogo src={app_logo} alt='App Logo' />
          <AppDescription>
            <p className='name'>MexPlay</p>
            <p className='desc'>{t('application')}</p>
          </AppDescription>
        </div>
        <DownloadBtn
          href={'https://apps.apple.com/mx/app/mexplay/id6469581767?l=en-GB'}
          target="blank">
          {t('download-app')}
        </DownloadBtn>
      </Container>
    </Portal>
  )
}

export default NiceModal.create(IosDownloadBanner)
