import { Route, lazy } from '@tanstack/router'

import { profileRoute } from '.'

const Commission = lazy(() => import('../../pages/profile/commission'))

export const commissionRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'commission',
  component: Commission,
})
