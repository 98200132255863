import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { User } from '../../store/slices/userSlice'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import type { PaymentChannel } from '../../types/wallet-management'
import { getAdsInfo } from '../../utils/ads-info'
import { api } from '../../utils/api-client'
import { getDeviceInfo } from '../../utils/device'
import { BannerPopupModal, ToastMessage } from '../common'
import MessageModal from '../common/message-modal'
import Spinner from '../common/spinner'
import { TailSpin } from '../loaders'
import AddBankBtn from '../payment-form/add-bank-btn'
import AmountSelector from '../payment-form/amount-selector'
import type { Promo } from '../payment-form/promotion-input'
import PromotionInput from '../payment-form/promotion-input'
import PromotionTermsModal from '../payment-form/promotion-terms-modal'
import TermsCheckbox from '../payment-form/terms-checkbox'
import AboutDeposit from './about-deposit'
import AmountInput from './amount-input'
import ConfirmButton from './confirm-button'
import Platform from './platform'

const KycBank = lazy(() => import('../../pages/kyc-bank'))
const KycBankList = lazy(() => import('../../pages/kyc-bank-list'))

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.6fr;
`

const ChannelsContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 4px solid var(--tertiary);
  padding: 48px 0 48px 48px;
  /* width */
  &::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .theme-dark &::-webkit-scrollbar-thumb {
    background-color: #000;
  }

  .theme-light &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
`

const Loading = styled.div`
  padding: 50px 0;
`

const FormContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 48px 96px;
  /* width */
  &::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .theme-dark &::-webkit-scrollbar-thumb {
    background-color: #000;
  }

  .theme-light &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
`

type DepositProps = {
  showWalletManagementModal: (page?: number, tab?: number) => void
  walletManagementDefaultTab?: {
    tab?: number
    page?: number
  }
}

const Deposit = ({
  showWalletManagementModal,
  walletManagementDefaultTab,
}: DepositProps) => {
  const email = useBoundStore(state => state.user.email)
  const phone = useBoundStore(state => state.user.phone)
  const selectedPaymentChannel = useBoundStore(
    state => state.payment.selectedPaymentChannel,
  )
  const setSelectedPaymentChannel = useBoundStore(
    state => state.payment.setSelectedPaymentChannel,
  )
  const setUser = useBoundStore(state => state.user.setUser)
  const promotionTermsModal = useModal(PromotionTermsModal)
  const messageModal = useModal(MessageModal)
  const bannerPopupModal = useModal(BannerPopupModal)
  const toastMessage = useModal(ToastMessage)
  const {
    t,
    i18n: { language },
  } = useTranslation('walletManagement')
  const [isDepositChannelsLoading, setIsDepositChannelsLoading] =
    useState(false)
  const [paymentChannels, setPaymentChannels] = useState<PaymentChannel[]>([])
  const [amount, setAmount] = useState<number>(500)
  const [selectedPromo, setSelectedPromo] = useState<Promo | null>(null)
  const [promoList, setPromoList] = useState<Promo[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isReadTerms, setIsReadTerms] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    api
      .post<ApiResponse<Array<Omit<Promo, 'label'> & { title: string }>>>(
        '/payment.php',
        {
          type: 'autopromotion_list',
          amount: amount,
        },
        { signal }
      )
      .then(res => {
        const { status, info } = res.data
        if (status === 1 && info) {
          const formatInfo = info.map(item => ({
            label: item.title,
            ...item,
          }))
          if (selectedPromo) {
            setSelectedPromo(prev => {
              const samePromo = formatInfo.find(
                promo => (prev as Promo).id === promo.id,
              )
              if (samePromo) {
                if (samePromo.disabled) {
                  return null
                }
                return samePromo
              }
              return formatInfo[0]
            })
          } else {
            const firstDepositBonus = formatInfo.find(
              promo => [234, 235].includes(+promo.id) && !promo.disabled,
            )
            if (firstDepositBonus) {
              bannerPopupModal.show({
                imgUrl: `https://www.u2d8899.com/mexicoimages/banner/100_bonus_${language}.webp`,
              })
            }
          }
          setPromoList(formatInfo)
        } else {
          setSelectedPromo(null)
          setPromoList([])
        }
      })

    return () => {
      controller.abort()
    }
  }, [amount])

  const onSubmit = async () => {
    if (!selectedPaymentChannel) return
    const reqBody = {
      type: 'submitpay',
      amount: amount,
      pay_type: 1,
      line_type: selectedPaymentChannel.line_type,
      autopromo: selectedPromo?.id ?? 0,
      pay_id: selectedPaymentChannel.id,
      email,
      meta: getDeviceInfo(),
      ads_info: getAdsInfo(),
    }

    setIsLoading(true)
    const response = await api.post<
      ApiResponse<{ form: string; message: string; data: { billno: string } }>
    >('/payment.php', reqBody)
    const { status, info } = response.data

    if (status === 1 || status === 2) {
      fbq('track', 'InitiateCheckout', {
        value: Number(amount),
        currency: 'MXN',
      })

      // Twitter
      twq('event', 'tw-olnlq-olnmq', {
        value: amount,
        currency: 'MXN',
        contents: [
          {
            transaction_id: info.data.billno,
            platform_id: selectedPaymentChannel.id,
            platform: selectedPaymentChannel.show_name,
            promotion: selectedPromo?.label ?? null,
            promotion_id: selectedPromo?.id ?? null,
          },
        ], 
        email_address: email,
        phone_number: `+52${phone}`,
      })

      if (selectedPaymentChannel.need_bank_card === 1) {
        setUser({
          pending_bind: 0,
          pending_amount: amount.toString(),
        } as User)
      }

      const submitForm = () => {
        const form = document.getElementById('pay_form') as HTMLFormElement
        if (form) {
          form.remove()
        }
        document
          .getElementById('root')
          ?.insertAdjacentHTML('beforeend', info.form)
        const newForm = document.getElementById('pay_form') as HTMLFormElement
        newForm?.submit()
      }

      if (status === 2) {
        messageModal.show({
          message: info.message,
          onClick: submitForm,
        })
      } else {
        submitForm()
      }
    } else {
      if (typeof info === 'string')
        messageModal.show({
          title: '',
          message: info,
        })
    }

    setIsLoading(false)
  }

  useEffect(() => {
    !selectedPaymentChannel && setSelectedPaymentChannel(paymentChannels[0])
  }, [selectedPaymentChannel])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    setIsDepositChannelsLoading(true)
    api
      .post<ApiResponse<PaymentChannel[]>>('/payment.php', {
        type: 'onlinepay_list_v1',
        payType: 0,
      }, { signal })
      .then(res => {
        const { status, info } = res.data
        if (status === 1) {
          setSelectedPaymentChannel(info[0])
          setPaymentChannels(info)
        }
        setIsDepositChannelsLoading(false)
      })

    return () => {
      controller.abort()
    }
  }, [])

  const onPromoSelect = (selected: Promo) => {
    if (selected.disabled_reason) {
      toastMessage.show({ text: selected.disabled_reason, isValid: false })
      return
    }
    setSelectedPromo(selected.id === selectedPromo?.id ? null : selected)
  }

  const renderButton = () => {
    if (!selectedPaymentChannel) return null
    if (isLoading)
      return (
        <div className="flx-ctr" style={{ height: '45px' }}>
          <TailSpin width={39} height={39} color="#fff" />
        </div>
      )

    return (
      <ConfirmButton
        disabled={
          !+amount ||
          +amount < selectedPaymentChannel.pay_limit.minimum ||
          +amount > selectedPaymentChannel.pay_limit.maximum ||
          (selectedPromo && selectedPromo.id !== 0 ? !isReadTerms : false)
        }
        onClick={onSubmit}>
        {t('confirm')}
      </ConfirmButton>
    )
  }

  const inputError = useMemo(() => {
    if (!selectedPaymentChannel) return ''
    if (+amount === 0 || +amount < selectedPaymentChannel.pay_limit.minimum)
      return `*${t('minimum-deposit')} ${
        selectedPaymentChannel.pay_limit.minimum
      }.`
    if (+amount > selectedPaymentChannel.pay_limit.maximum)
      return `*${t('maximum-deposit')} ${
        selectedPaymentChannel.pay_limit.maximum
      }.`
    return ''
  }, [amount, selectedPaymentChannel])

  if (!selectedPaymentChannel) return null

  const showPromoTermsModal = () => {
    selectedPromo &&
      promotionTermsModal.show({
        htmlContent: selectedPromo.content,
        promoId: selectedPromo.id.toString(),
      })
  }

  return (
    <Grid>
      <ChannelsContainer>
        {isDepositChannelsLoading && (
          <Loading className="flx-ctr">
            <TailSpin width={40} height={40} color="#fff" />
          </Loading>
        )}
        {!isDepositChannelsLoading &&
          paymentChannels.map((item, index) => (
            <Platform
              key={index}
              icon={item.img}
              label={item.show_name}
              route="deposit"
              active={selectedPaymentChannel?.id === item.id}
              onClick={() => {
                showWalletManagementModal(0, 0)
                setSelectedPaymentChannel(item)
              }}
            />
          ))}
      </ChannelsContainer>
      <FormContainer>
        {walletManagementDefaultTab?.page === 0 ||
        !walletManagementDefaultTab?.page ? (
          <>
            {selectedPaymentChannel && (
              <>
                <AmountInput
                  page={t('deposit-amount')}
                  tooltip={<AboutDeposit />}
                  amount={amount}
                  setAmount={setAmount}
                  error={inputError}
                />
                <AmountSelector
                  amounts={selectedPaymentChannel.fixed_amount}
                  amount={amount}
                  setAmount={setAmount}
                />
              </>
            )}
            <PromotionInput
              onPromoSelect={onPromoSelect}
              selectedPromo={selectedPromo}
              promoList={promoList}
            />
            {selectedPromo && selectedPromo.id !== 0 && (
              <TermsCheckbox
                isChecked={isReadTerms}
                setIsChecked={setIsReadTerms}
                onClick={showPromoTermsModal}
              />
            )}
            {selectedPaymentChannel?.need_bank_card === 1 && (
              <AddBankBtn onClick={() => showWalletManagementModal(1)} />
            )}
            {renderButton()}
          </>
        ) : walletManagementDefaultTab?.page === 1 ? (
          <Suspense fallback={<Spinner />}>
            <KycBank
              goToBankCards={() => showWalletManagementModal(2)}
              onCancel={() => showWalletManagementModal(0)}
              showWalletManagementModal={showWalletManagementModal}
              method="deposit"
            />
          </Suspense>
        ) : (
          <Suspense fallback={<Spinner />}>
            <KycBankList
              goToKycBank={() => showWalletManagementModal(1)}
              showWalletManagementModal={showWalletManagementModal}
              method="deposit"
            />
          </Suspense>
        )}
      </FormContainer>
    </Grid>
  )
}

export default Deposit
