import { styled } from '@linaria/react'

import type { AnnouncementObj } from '../../types/announcement'

const Container = styled.div`
  background-color: var(--tertiary);
  padding: 16px;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  gap: 10px;
  margin-bottom: 12px;
  border-radius: 10px;
  overflow: hidden;
`

const Date = styled.p`
  font-size: 16px;
  color: var(--txt);
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

const Content = styled.p`
  font-size: 16px;
  color: var(--txt);
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

const AnnouncementItem = ({ edit_time, content }: AnnouncementObj) => {
  return (
    <Container>
      <Date>{edit_time}</Date>
      <Content>{content}</Content>
    </Container>
  )
}

export default AnnouncementItem
