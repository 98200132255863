import type { InternalAxiosRequestConfig } from 'axios'
import qs from 'qs'

import { QS_CONFIG } from '../../constants/api'
import { decryptUser } from '../user'

export function addCredentialsToRequest(
  request: InternalAxiosRequestConfig,
) {
  const user = decryptUser()
  const privateAuth = localStorage.getItem('privateAuth')

  if (request.data instanceof FormData) {
    const auth = request.data.get('auth')
    const username = request.data.get('username')
    const password = request.data.get('password')

    !auth && privateAuth && request.data.append('auth', privateAuth)
    !username &&
      user?.username &&
      request.data.append('username', user?.username)
    !password &&
      user?.password &&
      request.data.append('password', user?.password)
  } else if (typeof request.data === 'string') {
    let requestData = qs.parse(request.data)
    const { auth, username, password } = requestData

    if (!auth && privateAuth)
      requestData = { ...requestData, auth: privateAuth }

    if (!username && user?.username)
      requestData = { ...requestData, username: user?.username }

    if (!password && user?.password)
      requestData = { ...requestData, password: user?.password }

    const updatedRequestDataQueryString = qs.stringify(requestData, QS_CONFIG)

    request.data = updatedRequestDataQueryString
  }

  return request
}
