import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const Register = lazy(() => import('../pages/register'))

export type RegisterSearch = {
  referral?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  uid?: string
}

export const registerRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'register',
  component: Register,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return {
      utm_campaign: typeof search.utm_campaign === 'string' ? search.utm_campaign : '',
      utm_medium: typeof search.utm_medium === 'string' ? search.utm_medium : '',
      utm_source: typeof search.utm_source === 'string' ? search.utm_source : '',
      uid: typeof search.uid === 'string' ? search.uid : '',
    }
  },
})
