import { Route, lazy } from '@tanstack/router'

import type { VipTab } from '../components/vip/header'
import { rootRoute } from './root'

const Vip = lazy(() => import('../pages/vip'))

type VipSearch = {
  tab: VipTab
  view?: string
}

export const vipRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'vip',
  component: Vip,
  validateSearch: (search: Record<string, unknown>): VipSearch => {
    return {
      tab:
        search.tab === 'system' || search.tab === 'privileges'
          ? search.tab
          : 'privileges',
      view: typeof search.view === 'string' ? search.view : '',
    }
  },
})
