import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const Sports = lazy(() => import('../pages/sports'))

type SportsSearch = {
  id?: string
}

export const sportsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'sports',
  component: Sports,
  validateSearch: (search: Record<string, unknown>): SportsSearch => {
    return {
      id: typeof search.id === 'string' ? search.id : '',
    }
  },
})
