import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const SportsAds = lazy(() => import('../pages/sports-ads'))

type SportsAdsSearch = {
  referral?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  bonus?: string
}

export const sportsAdsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'sportsads',
  component: SportsAds,
  validateSearch: (search: Record<string, unknown>): SportsAdsSearch => {
    return {
      utm_campaign: typeof search.utm_campaign === 'string' ? search.utm_campaign : '',
      utm_medium: typeof search.utm_medium === 'string' ? search.utm_medium : '',
      utm_source: typeof search.utm_source === 'string' ? search.utm_source : '',
    }
  },
})
