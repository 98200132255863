import { Route, lazy } from '@tanstack/router'

import { profileRoute } from '.'

const TransactionHistory = lazy(
  () => import('../../pages/profile/transaction-history'),
)

export const transactionHistoryRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'transaction-history',
  component: TransactionHistory,
})
