import { useState } from 'react'
import { api } from '../utils/api-client'
import { ApiResponse } from '../types/api'
import { useBoundStore } from '../store/store'

const useRefreshBalance = () => {
  const setBalance = useBoundStore(state => state.user.setBalance)
  const [isLoading, setIsLoading] = useState(false)

  const refreshBalance = async () => {
    setIsLoading(true)
    try {
      const response = await api.post<
        ApiResponse<{
          balance: number
          pending_bind: number
        }>
      >('/ajax_data.php', {
        gameid: 0,
        type: 'get_balance',
      })
      const { status, info } = response.data
      if (status === 1) {
        setBalance(info)
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return { refreshBalance, isLoading }
}

export default useRefreshBalance