import { AxiosError } from 'axios'
import qs from 'qs'

import { RequestPath, api } from '.'
import { QS_CONFIG } from '../../constants/api'
import type { ApiResponse } from '../../types/api'
import { getPublicAuth } from '../getPublicAuth'
import { decryptUser } from '../user'

export async function refreshAuthToken(error: AxiosError<unknown>) {
  const user = decryptUser()

  if (error.response && error.response.status === 401 && user) {
    const publicAuth = await getPublicAuth()

    const updatedTokenResponse = await api.post<
      ApiResponse<{ auth: string; expired_time: number } | string>
    >('/link.php', {
      ...user,
      auth: publicAuth,
    })
    const { status, info } = updatedTokenResponse.data

    if (status === 1 && typeof info !== 'string') {
      const updatedAuth = info.auth
      localStorage.setItem('privateAuth', updatedAuth)

      const path = error.response.config.url as RequestPath
      const config = error.response.config
      const requestData = error.response.config.data as FormData | string
      if (requestData instanceof FormData) {
        requestData.append('auth', updatedAuth)
        const responseWithUpdatedAuth = await api.http(path, {
          ...config,
          data: requestData,
        })

        return responseWithUpdatedAuth
      } else {
        const parsedRequestData = qs.parse(requestData)
        parsedRequestData.auth = updatedAuth

        const responseWithUpdatedAuth = await api.http(path, {
          ...config,
          data: qs.stringify(parsedRequestData, QS_CONFIG),
        })

        return responseWithUpdatedAuth
      }
    }
  }

  return error
}
