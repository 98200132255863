import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend, {
  type ChainedBackendOptions,
} from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'

i18n
  // https://github.com/i18next/i18next-chained-backend
  .use(ChainedBackend)
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // https://www.i18next.com/overview/configuration-options
  .init<ChainedBackendOptions>({
    debug: !import.meta.env.PROD,
    supportedLngs: ['es', 'en'],
    fallbackLng: 'es',
    defaultNS: 'common',
    ns: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    partialBundledLanguages: true,
    backend: {
      backends: [
        LocalStorageBackend, // primary backend - https://github.com/i18next/i18next-localstorage-backend
        HttpApi, // secondary backend - https://github.com/i18next/i18next-http-backend
      ],
      backendOptions: [
        {
          /* options for primary backend */
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
          defaultVersion: 'v51.8', // Version applied to all languages, can be overridden using the option `versions`
        },
        {
          /* options for secondary backend */
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  })

export default i18n
