import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { styled } from '@linaria/react'
import { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import { useBoundStore } from '../../store/store'
import { UserInfo } from '../../types/user'
import { encryptUser } from '../../utils/user'
import Icon from '../icon'

// const InstagramIcon = (
//   <svg width="40" height="40" viewBox="0 0 200 200">
//     <defs>
//       <radialGradient
//         id="radialGradient"
//         cx="-21.85"
//         cy="270.1"
//         r="416.95"
//         gradientUnits="userSpaceOnUse">
//         <stop offset="0" stopColor="#f9ed41" />
//         <stop offset="0.24" stopColor="#ff833d" />
//         <stop offset="0.4" stopColor="#ee5568" />
//         <stop offset="0.49" stopColor="#e7407b" />
//         <stop offset="1" stopColor="#0028ff" />
//       </radialGradient>
//     </defs>
//     <rect fill="url(#radialGradient)" width="200" height="200" rx="100" />
//     <path
//       fill="#fff"
//       d="M121.87,156.4H78.13A34.57,34.57,0,0,1,43.6,121.87V78.13A34.57,34.57,0,0,1,78.13,43.6h43.74A34.57,34.57,0,0,1,156.4,78.13v43.74A34.57,34.57,0,0,1,121.87,156.4ZM78.13,52A26.13,26.13,0,0,0,52,78.13v43.74A26.13,26.13,0,0,0,78.13,148h43.74A26.13,26.13,0,0,0,148,121.87V78.13A26.13,26.13,0,0,0,121.87,52Z"
//     />
//     <path
//       fill="#fff"
//       d="M100,128.53A28.53,28.53,0,1,1,128.53,100,28.56,28.56,0,0,1,100,128.53Zm0-48.42A19.89,19.89,0,1,0,119.88,100,19.91,19.91,0,0,0,100,80.11Z"
//     />
//     <path
//       fill="#fff"
//       d="M139.07,68.36a7.43,7.43,0,1,1-7.43-7.43A7.43,7.43,0,0,1,139.07,68.36Z"
//     />
//   </svg>
// )

const PlatformButtonsContainer = styled.div`
  margin-top: 12px;
  gap: 12px;
  display: flex;
  justify-content: center;

  > button {
    width: 40px;
    height: 40px;
  }
`

type ThirdPartyPlatforms = 'facebook' | 'telegram' | 'google' | 'twitter' | 'twitch'

interface SocialMediaAuthProps {
  callback?: (user: UserInfo, status: number) => void
  setError: (message: string) => void
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const SocialMediaAuth = ({
  callback,
  setError,
  isLoading,
  setIsLoading,
}: SocialMediaAuthProps) => {
  const enableFacebook = useBoundStore(state => state.settings.login_facebook)
  const enableTelegram = useBoundStore(state => state.settings.login_telegram)
  const enableGoogle = useBoundStore(state => state.settings.login_google)
  const enableTwitter = useBoundStore(state => state.settings.login_twitter)
  const enableTwitch = useBoundStore(state => state.settings.login_twitch)

  const PLATFORMS = [
    { icon: <Icon id="facebook" width="40" height="40" />, key: 'facebook', enabled: !!enableFacebook.value },
    { icon: <Icon id="telegram" width="40" height="40" />, key: 'telegram', enabled: !!enableTelegram.value },
    { icon: <Icon id="google" width="40" height="40" />, key: 'google', enabled: !!enableGoogle.value },
    { icon: <Icon id="twitter-2" width="40" height="40" />, key: 'twitter', enabled: !!enableTwitter.value },
    { icon: <Icon id="twitch" width="40" height="40" />, key: 'twitch', enabled: !!enableTwitch.value },
    // { icon: <Icon id="whatsapp" width="40" height="40" />, key: 'whatsapp' },
  ] as const

  const FILTERED_PLATFORMS = PLATFORMS.filter((platform) => platform.enabled)

  const setUserStore = useBoundStore(state => state.user.setUser)
  const [thirdPartyLoading, setThirdPartyLoading] =
    useState<ThirdPartyPlatforms | null>(null)

  const checkLoginStatus = (hash: string, platform: ThirdPartyPlatforms) => {
    const interval = setInterval(async () => {
      const response = await api.get<
        ApiResponse<(UserInfo & { password?: string }) | string>
      >(`/oauth/${platform}/callback.php?auth_id=${hash}`)
      const { status, info } = response.data

      if (status === 0 && typeof info === 'string') {
        clearInterval(interval)
        setIsLoading(false)
        setThirdPartyLoading(null)
        setError(info)
      }

      if ((status === 1 || status === 2) && typeof info !== 'string') {
        clearInterval(interval)
        encryptUser({
          username: info.username,
          password: info.password as string,
        })
        delete info.password
        setUserStore(info)
        setIsLoading(false)
        setThirdPartyLoading(null)
        callback?.(info, status)
      }
    }, 1000)
  }

  const handleThirdPartyLogin = async (
    platform: ThirdPartyPlatforms,
    windowRef: Window | null,
  ) => {
    if (isLoading) return

    setThirdPartyLoading(platform)
    setIsLoading(true)
    const response = await api.get<
      ApiResponse<
        | {
            url: string
            id: string
          }
        | string
      >
    >(`/oauth/${platform}/authenticate.php`)
    const { status, info } = response.data

    if (status === 0 && typeof info === 'string') {
      return setError(info)
    }

    if (typeof info !== 'string') {
      const { url, id } = info
      windowRef?.location.assign(url)
      checkLoginStatus(id, platform)
    }
  }

  const getOpacity = useCallback(
    (platform: ThirdPartyPlatforms) => {
      if (!isLoading) return undefined
      if (platform === thirdPartyLoading) return 1
      return 0.4
    },
    [isLoading, thirdPartyLoading],
  )

  if (FILTERED_PLATFORMS.length === 0) return null

  return (
    <PlatformButtonsContainer>
      {FILTERED_PLATFORMS.map(plaform => (
        <button
          key={plaform.key}
          onClick={() => {
            const windowRef = window.open()
            handleThirdPartyLogin(plaform.key, windowRef)
          }}
          style={{ opacity: getOpacity(plaform.key) }}>
          {plaform.icon}
        </button>
      ))}
    </PlatformButtonsContainer>
  )
}

export default SocialMediaAuth
