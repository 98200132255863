import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'
import Icon from '../icon'

const Container = styled.div`
  margin-bottom: 20px;
  align-items: flex-start;
`

const Text = styled.p`
  font-size: 14px;
  color: var(--txt);
  margin-left: 8px;
  button {
    font-size: 14px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    button {
      font-size: 16px;
    }
  }
`

const Button = styled.button`
  text-decoration: underline;
  color: var(--txt);
  font-size: 12px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const Checkbox = styled.button`
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  border: 1.5px solid var(--txt);
  svg {
    stroke: var(--txt);
  }
`

interface TermsCheckboxProps {
  isChecked: boolean
  setIsChecked: Dispatch<SetStateAction<boolean>>
  onClick: () => void
}

const TermsCheckbox = ({
  isChecked,
  setIsChecked,
  onClick,
}: TermsCheckboxProps) => {
  const { t } = useTranslation('walletManagement')

  return (
    <Container className="flx-ctr">
      <Checkbox
        className="flx-ctr"
        onClick={() => setIsChecked(prev => (prev ? false : true))}>
        {isChecked && <Icon id="check" width={20} height={20} />}
      </Checkbox>
      <Text>
        {t('read-the-terms')} <Button onClick={onClick}>{t('terms')}</Button>{' '}
        {t('this-promo')}
      </Text>
    </Container>
  )
}

export default TermsCheckbox
