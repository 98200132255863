import { RouterProvider } from '@tanstack/router'
import smoothscroll from 'smoothscroll-polyfill'
import TanstackRouterDevTools from './components/dev-tools/tanstack-router-dev-tools'
import withLogin from './hoc/withLogin'
import { router } from './router'

smoothscroll.polyfill()

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <TanstackRouterDevTools router={router} />
    </>
  )
}

export default withLogin(App)
