import { LiveChatWidget } from '@livechat/widget-react'

import customerService from '../../assets/common/customer_service.svg'
import { useBoundStore } from '../../store/store'

export const CustomerServiceButton = () => {
  return (
    <button
      className="cursor-pointer transition-all duration-[0.2s] ease-[ease-in-out] flex hover:scale-105"
      id="float-customer-service"
      aria-label="Contact customer service">
      <img
        className="w-[50px] h-[51.66px] pointer-events-none"
        src={customerService}
        style={{ width: 50, height: 51.66 }}
        alt="customer service button"
      />
    </button>
  )
}

const CustomerService = () => {
  const email = useBoundStore(state => state.user.email)
  const isChatVisible = useBoundStore(state => state.common.isChatVisible)
  const setIsChatVisible = useBoundStore(state => state.common.setIsChatVisible)
  const username = useBoundStore(state => state.user.username)

  return (
    <LiveChatWidget
      license="13100400"
      visibility={isChatVisible ? 'maximized' : 'minimized'}
      customerName={username ?? ''}
      customerEmail={email ?? ''}
      onVisibilityChanged={data =>
        setIsChatVisible(data.visibility === 'maximized')
      }
    />
  )
}

export default CustomerService
