import { AxiosRequestConfig } from 'axios'
import qs from 'qs'

const API_LIST = ['play_game_websocket', 'avatar_verify_2', 'daily_deposit']

export function isApi2(requestConfig: AxiosRequestConfig) {
  const { data } = requestConfig

  const type =
    data instanceof FormData
      ? data.get('type')
      : typeof data === 'string'
      ? qs.parse(data).type
      : ''

  const submitType =
    data instanceof FormData
      ? data.get('submit_type')
      : typeof data === 'string'
      ? qs.parse(data).submit_type
      : ''

  if (typeof type === 'string') {
    return API_LIST.includes(type)
  }

  if (typeof submitType === 'string') {
    return API_LIST.includes(submitType)
  }

  return false
}
