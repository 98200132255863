import { Route, lazy } from '@tanstack/router'

import { referralRoute } from '.'

const MyInvitation = lazy(
  () => import('../../../pages/profile/referral/my-invitation'),
)

export const myInvitationRoute = new Route({
  getParentRoute: () => referralRoute,
  path: 'my-invitation',
  component: MyInvitation,
})
