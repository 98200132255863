import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

import lock from '../../assets/wallet-management/lock.webp'

const NoteContainer = styled.div`
  padding-bottom: 12px;
  &.verified {
    padding-bottom: 16px;
  }
`

const Note = styled.span`
  font-family: 'Avenir-Light';
  font-size: 12px;
  color: #999;
  margin-left: 12px;
  @media (min-width: 1200px) {
    font-size: 14px;
  }
`

const Lock = styled.img`
  width: 40px;
  min-width: 40px;
  @media (min-width: 1200px) {
    width: 45px;
    min-width: 45px;
  }
`

const SecurityNote = () => {
  const { t } = useTranslation('walletManagement')

  return (
    <NoteContainer className="flx-ctr">
      <Lock src={lock} />
      <Note>{t('security-note')}</Note>
    </NoteContainer>
  )
}

export default SecurityNote
