import { Route, lazy } from '@tanstack/router'

import { profileRoute } from '..'

const ReferralLayout = lazy(() => import('../../../pages/profile/referral'))

export const referralRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'referral',
  component: ReferralLayout,
})
