import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const FooterPage = lazy(() => import('../pages/footer-page'))

export const footerPageRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'footer/$page',
  component: FooterPage,
})
