import { Route, lazy } from '@tanstack/router'

import { depositRoute } from '.'

const PaymentForm = lazy(() => import('../../pages/deposit/payment-form'))

export const paymentFormRoute = new Route({
  getParentRoute: () => depositRoute,
  path: '/$id',
  component: PaymentForm,
})
