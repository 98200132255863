import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'

import closeModal from '../../utils/closeModal'
import Icon from '../icon'
import modalBg from '../../assets/common/modal_bg.webp'
import Modal from '../common/modal'
import vortex from '../../assets/referral/vortex.gif'
import falling_coins from '../../assets/referral/falling_coins.gif'
import { Fragment, useEffect, useState } from 'react'
import { useBoundStore } from '../../store/store'
import { numberWithCommas } from '../../utils/number'
import { api } from '../../utils/api-client'
import { ApiResponse } from '../../types/api'
import { TailSpin } from '../loaders'

const container = css`
  padding: 84px 50px 40px 65px;
  border-color: none;
  border: none;
  background: url(${modalBg});
  background-size: contain;
  background-repeat: repeat;
  aspect-ratio: 241 / 213;
  width: 360px;
  max-width: unset;
  @media (min-width: 1200px) {
    padding: 140px 70px 40px 85px;
    width: 600px;
  }
`

const Close = styled.button`
  display: flex;
  position: absolute;
  padding: 16px;
  top: 0;
  right: 0;
  &:hover {
    opacity: 0.8;
  }
  @media (min-width: 1200px) {
    padding: 20px;
  }
`

const Message = styled.p`
  font-family: 'Avenir-Light';
  font-size: 12px;
  color: var(--txt);
  text-align: center;
  text-shadow: 2px 2px #000;
  &.bold {
    font-family: 'Avenir-Heavy';
    font-size: 14px;
    margin-top: 4px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    &.bold {
      font-size: 20px;
      margin-top: 12px;
    }
  }
`

const Prize = styled.p`
  font-family: 'Avenir-Heavy';
  text-shadow: 2px 2px #d20c69ff;
  font-size: 44px;
  line-height: 48px;
  color: var(--txt);
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 66px;
    line-height: 76px;
  }
`

const ClickHere = styled.button`
  height: 25px;
  background-color: #df0033;
  border-radius: 2px;
  padding: 0 16px;
  margin: 12px auto 0;
  width: max-content;
  width: 70px;
  p {
    font-size: 10px;
    line-height: 25px;
    color: #fff;
    font-family: 'Syncopate-Bold';
    text-transform: uppercase;
    text-align: center;
  }
  @media (min-width: 1200px) {
    height: 40px;
    margin: 24px auto 0;
    width: 100px;
    p {
      font-size: 14px;
      line-height: 35px;
    }
  }
`

const Title = styled.h3`
  font-family: 'Syncopate-Bold';
  font-size: 15px;
  line-height: 15px;
  width: 100%;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #f4f4f4ff;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: #d20c69ff;
  margin: 0 auto 3px;
  @media (min-width: 1200px) {
    font-size: 26px;
    line-height: 30px;
    -webkit-text-stroke-width: 2px;
    margin: 0 auto 6px;
  }
`

const Vortex = styled.img`
  position: fixed;
  width: 360px;
  top: calc(50% - 90px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 357;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 1s ease-in-out forwards;
  @media (min-width: 1200px) {
    top: calc(50% - 140px);
    width: 600px;
  }
`

const FallingCoins = styled.img`
  position: fixed;
  width: 360px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 357;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 1s ease-in-out forwards;
  @media (min-width: 1200px) {
    width: 600px;
  }
`

const ReferralCreditModal = () => {
  const coinDropAmount = useBoundStore(state => state.user.coin_drop_amount)
  const coinDropIds = useBoundStore(state => state.user.coin_drop_ids)
  const { t } = useTranslation(['referral', 'common'])
  const modal = useModal()
  const [delayVisible, setDelayVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (modal.visible) {
      setTimeout(() => setDelayVisible(true), 2000)
    }
  }, [modal.visible])

  const claim = async () => {
    setIsLoading(true)
    try {
      await api.post<ApiResponse<string>>('/request.php', {
        ids: coinDropIds,
        type: 'update_notifications',
      })
      closeModal(modal)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <Fragment>
      <FallingCoins
        src={falling_coins}
      />
      {delayVisible && (
        <>
          <Vortex src={vortex} style={{ opacity: modal.visible ? 1 : 0 }} />
          <Modal
            id="referral-credit-modal"
            containerStyle={container}
            zIndex={356}
            onModalClose={claim}>
            <Close onClick={claim}>
              <Icon id="close" width="15" height="15" fill="#fff" />
            </Close>
            <Title>{t('congratulations', { ns: 'common' })}</Title>
            <Message>{t('you-got')}</Message>
            <Prize>${numberWithCommas(+coinDropAmount)}</Prize>
            <Message>({t('referral-prize')})</Message>
            <Message className="bold">{t('refer-more')}</Message>
            <Message>{t('check-amount')}</Message>
            <ClickHere className="flx-ctr" disabled={isLoading} onClick={claim}>
              {isLoading ? (
                <TailSpin width={15} height={15} />
              ) : (
                <p>{t('ok', { ns: 'common' })}</p>
              )}
            </ClickHere>
          </Modal>
        </>
      )}
    </Fragment>
  )
}

export default NiceModal.create(ReferralCreditModal)
