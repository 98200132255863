import { Outlet, Route } from '@tanstack/router'

import protectRoute from '../../hoc/protectRoute'
import { rootRoute } from '../root'

export const withdrawRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'withdraw',
  component: protectRoute(Outlet, true),
})
