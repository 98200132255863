import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { useNavigate } from '@tanstack/router'
import { lazy, Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  WITHDRAW_CHANNELS_EN,
  WITHDRAW_CHANNELS_ES,
} from '../../constants/wallet-management'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import type { WalletDetails } from '../../types/wallet-management'
import { api } from '../../utils/api-client'
import { Spinner } from '../common'
import MessageModal from '../common/message-modal'
import { TailSpin } from '../loaders'
import AboutWithdraw from '../withdraw-form/about-withdraw'
import ActionButtons from '../withdraw-form/action-buttons'
import AddBank from '../withdraw-form/add-bank'
import AddUsdt from '../withdraw-form/add-usdt'
import Balance from '../withdraw-form/balance'
import SelectWallet from '../withdraw-form/select-wallet'
import AmountInput from './amount-input'
import ConfirmButton from './confirm-button'
import Platform from './platform'
import WithdrawHistory from './withdraw-history'

const KycBank = lazy(() => import('../../pages/kyc-bank'))
const KycBankList = lazy(() => import('../../pages/kyc-bank-list'))

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.6fr;
`

const ChannelsContainer = styled.div`
  width: 100%;
  border-right: 4px solid var(--tertiary);
  padding: 48px 0 48px 48px;
`

const FormContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 48px 96px;
  /* width */
  &::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .theme-dark &::-webkit-scrollbar-thumb {
    background-color: #000;
  }

  .theme-light &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
`

type WithdrawProps = {
  closeWalletManagementModal: () => void
  showWalletManagementModal: (page?: number, tab?: number) => void
  walletManagementDefaultTab?: {
    tab?: number
    page?: number
  }
}

const Withdraw = ({
  closeWalletManagementModal,
  showWalletManagementModal,
  walletManagementDefaultTab,
}: WithdrawProps) => {
  const navigate = useNavigate()
  const isVerified = useBoundStore(state => state.user.is_verified) 
  const { t, i18n } = useTranslation(['common', 'walletManagement'])
  const { language } = i18n
  const messageModal = useModal(MessageModal)
  const channels =
    language === 'es' ? WITHDRAW_CHANNELS_ES : WITHDRAW_CHANNELS_EN
  const [selectedChannel, setSelectedChannel] = useState(0)
  const [amount, setAmount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [walletList, setWalletList] = useState<WalletDetails[]>([])
  const [selectedWallet, setSelectedWallet] = useState<WalletDetails | null>(
    null,
  )

  const withdrawHandler = async () => {
    if (!selectedWallet) return

    if (+isVerified !== 1) {
      messageModal.show({
        message: t('walletManagement:please-do-kyc'),
        onClick: () => {
          closeWalletManagementModal()
          navigate({ to: '/profile/kyc' })
        },
      })
      return
    }

    const reqBody = {
      submit_type: 'debit',
      debit_bank: selectedWallet.id,
      amount: amount,
    }

    setIsLoading(true)
    const response = await api.post<ApiResponse<string>>('/center.php', reqBody)
    const { status, info } = response.data
    if (status === 1) {
      messageModal.show({
        title: t('successful', { ns: 'common' }),
        message: t('processing', { ns: 'walletManagement' }),
      })
    } else {
      messageModal.show({
        title: '',
        message: info,
      })
    }
    setIsLoading(false)
  }

  const getWalletList = async () => {
    const response = await api.post<ApiResponse<WalletDetails[]>>(
      '/ajax_data.php',
      { type: 'bank_list' },
    )
    const { status, info } = response.data
    if (status === 1) {
      if (selectedChannel === 0)
        setWalletList(
          info.filter(
            item =>
              item.bank_type !== 'USDTERC20' && item.bank_type !== 'USDTTRC20',
          ),
        )
      else if (selectedChannel === 1)
        setWalletList(
          info.filter(
            item =>
              item.bank_type === 'USDTERC20' || item.bank_type === 'USDTTRC20',
          ),
        )
    }
  }

  const renderButton = () => {
    if (isLoading)
      return (
        <div className="flx-ctr" style={{ height: '60px' }}>
          <TailSpin width={39} height={39} color="#fff" />
        </div>
      )

    return (
      <ConfirmButton
        onClick={withdrawHandler}
        disabled={amount <= 0 || selectedWallet === null}>
        {t('withdraw', { ns: 'walletManagement' })}
      </ConfirmButton>
    )
  }

  const renderRightContent = () => {
    if (walletManagementDefaultTab?.page === 1) {
      if (selectedChannel === 0)
        return (
          <AddBank
            getWalletList={getWalletList}
            isVisible={true}
            setIsVisible={isVisible =>
              showWalletManagementModal(isVisible ? 1 : 0)
            }
          />
        )

      return (
        <AddUsdt
          getWalletList={getWalletList}
          isVisible={true}
          setIsVisible={isVisible =>
            showWalletManagementModal(isVisible ? 1 : 0)
          }
        />
      )
    }

    if (walletManagementDefaultTab?.page === 2) {
      return (
        <Suspense fallback={<Spinner />}>
          <KycBank
            goToBankCards={() => showWalletManagementModal(3)}
            onCancel={() => showWalletManagementModal(0)}
            showWalletManagementModal={showWalletManagementModal}
            method="withdraw"
          />
        </Suspense>
      )
    }

    if (walletManagementDefaultTab?.page === 3) {
      return (
        <Suspense fallback={<Spinner />}>
          <KycBankList
            goToKycBank={() => showWalletManagementModal(2)}
            showWalletManagementModal={showWalletManagementModal}
            method="withdraw"
          />
        </Suspense>
      )
    }

    return (
      <>
        <Balance />
        <SelectWallet
          type={selectedChannel === 0 ? 'bank' : 'usdt'}
          value={selectedWallet ? selectedWallet.debit_bank : ''}
          walletList={walletList}
          setSelectedWallet={setSelectedWallet}
        />
        <ActionButtons
          type={selectedChannel === 0 ? 'bank' : 'usdt'}
          onAdd={() => showWalletManagementModal(1)}
        />
        <AmountInput
          type={selectedChannel === 0 ? 'bank' : 'usdt'}
          page={t('withdrawal-amount', { ns: 'walletManagement' })}
          amount={amount}
          setAmount={setAmount}
        />
        {renderButton()}
        <AboutWithdraw />
      </>
    )
  }

  useEffect(() => {
    getWalletList()
  }, [selectedChannel])

  return (
    <Grid>
      <ChannelsContainer>
        {channels.map((item, index) => (
          <Platform
            key={index}
            icon={item.icon}
            label={item.label}
            active={index === selectedChannel}
            onClick={() => setSelectedChannel(index)}
            route="withdraw"
          />
        ))}
        <WithdrawHistory
          closeWalletManagementModal={closeWalletManagementModal}
        />
      </ChannelsContainer>
      <FormContainer>{renderRightContent()}</FormContainer>
    </Grid>
  )
}

export default Withdraw
