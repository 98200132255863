import NiceModal from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import axios from 'axios'
import { useEffect, useState } from 'react'

import useDimensions from '../../hooks/useDimensions'
import type { AnnouncementObj } from '../../types/announcement'
import type { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import { AnnouncementItem, Header } from '../announcements'
import Modal from './modal'
import Spinner from './spinner'

const container = css`
  max-width: 800px;
  border-radius: 8px;
  border-width: 0px;
`

const Content = styled.div`
  padding: 0 24px 12px;
  height: 500px;
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 9px;
    background-color: rgba(32, 38, 50, 1);
    background-clip: content-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(32, 38, 50, 0.8);
  }
`

const AllAnnouncementsModal = () => {
  const { isDesktop } = useDimensions()
  const [isLoading, setIsLoading] = useState(false)
  const [announcements, setAnnouncements] = useState<AnnouncementObj[]>([])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    setIsLoading(true)
    api
      .post<ApiResponse<AnnouncementObj[]>>('/ajax_check.php', {
        type: 'get_notice',
        num: 1,
      }, { signal })
      .then(res => {
        const { status, info } = res.data
        if (status === 1) {
          setAnnouncements(info)
        }
        setIsLoading(false)
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err)
        }
        setIsLoading(false)
      })

      return () => {
        controller.abort()
      }
  }, [])

  if (!isDesktop) return null

  const renderList = () => {
    if (isLoading) return <Spinner containerStyle={{ height: '200px' }} />

    return announcements.map((item, index) => (
      <AnnouncementItem key={index} {...item} />
    ))
  }

  return (
    <Modal id="all-announcements-modal" containerStyle={container}>
      <Header />
      <Content>{renderList()}</Content>
    </Modal>
  )
}

export default NiceModal.create(AllAnnouncementsModal)
