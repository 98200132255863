import { styled } from '@linaria/react'
import { useTranslation } from 'react-i18next'

import type { WithdrawTypes } from '../../types/wallet-management'
import Icon from '../icon'

const Container = styled.div`
  margin-bottom: 20px;
  gap: 6px;
  justify-content: center;
  @media (min-width: 1200px) {
    margin-bottom: 24px;
    gap: 10px;
  }
`

const Button = styled.button`
  width: 100%;
  height: 45px;
  background-color: #65676a;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  font-family: 'Avenir-Medium';
  &.primary {
    background-color: #df0033;
  }
  svg {
    margin-right: 5px;
    margin-bottom: 1px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    height: 50px;
  }
`

interface ActionButtonsProps {
  onAdd: () => void
  type: WithdrawTypes
}

const ActionButtons = ({
  onAdd,
  type,
}: ActionButtonsProps) => {
  const { t } = useTranslation('walletManagement')

  return (
    <Container>
      <Button className="flx-ctr primary" onClick={onAdd}>
        <Icon id="plus" width="12" fill="#fff" />
        {t('add')}{' '}
        {type === 'bank' ? `${t('bank-account')}` : `${t('usdt-address')}`}
      </Button>
    </Container>
  )
}

export default ActionButtons
