import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useEffect, useRef, useState, type UIEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'

import closeModal from '../../utils/closeModal'
import Modal from './modal'
import { debounce } from '../../utils/common'
import Icon from '../icon'

const container = css`
  padding: 20px;
`

const ScrollList = styled.div`
  height: 190px;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  margin-bottom: 20px;
  /* width */
  &::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  .theme-dark &::-webkit-scrollbar-thumb {
    background-color: #2c223a;
  }
  .theme-light &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
`

const Active = styled.div`
  height: 38px;
  border-top: 1px solid var(--txt);
  border-bottom: 1px solid var(--txt);
  position: absolute;
  top: 96px;
  right: 20px;
  width: calc(100% - 40px);
  z-index: -1;
`

const Tooltip = styled.p`
  color: #202632;
  position: absolute;
  bottom: 210px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  border-radius: 5px;
  pointer-events: none;
  z-index: 225;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  font-family: 'Avenir-Light';
  font-size: 12px;
  width: max-content;
  max-width: calc(100vw - 40px);
  background-color: #eee;
  .arrow {
    width: 7px;
    position: absolute;
    top: calc(100% - 5px);
    transform: translateX(50%) rotate(-90deg);
    right: 50%;
    fill: #eee;
  }
  .dark {
    & {
      background-color: #fff;
    }
    .arrow {
      fill: #fff;
    }
  }
  @media (min-width: 1200px) {
    font-size: 14px;
  }
`

const ScrollItem = styled.p`
  text-align: center;
  font-family: 'Avenir-Light';
  color: rgb(179, 179, 179);
  scroll-snap-align: start;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  line-height: 38px;
  &.active {
    color: var(--txt);
  }
`

const Blank = styled.div`
  height: 38px;
  width: 100%;
  scroll-snap-align: start;
`

const Button = styled.button`
  width: 47%;
  text-align: center;
  font-size: 16px;
  font-family: 'Avenir-Light';
  padding: 7px 0;
  background-color: var(--ok-btn-bg);
  border: 1.5px solid white;
  color: var(--ok-btn-txt);
  border-radius: 22px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &.secondary {
    background-color: transparent;
  }
  .theme-dark &.secondary {
    border: 1.5px solid #fff;
    color: #fff;
  }
  .theme-light &.secondary {
    border: 1.5px solid #888;
    color: #888;
  }
  &:disabled {
    opacity: 0.6;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`

export interface Item {
  label: string
  id: string | number
  disabled?: 1 | 0
  disabled_reason?: string
}

interface ScrollSelectorProps {
  items: Array<Item>
  selected: Item | null
  onOk: (item: Item) => void
}

const ScrollSelector = ({ items, onOk, selected }: ScrollSelectorProps) => {
  const modal = useModal()
  const { t } = useTranslation('common')
  const scrollRef = useRef<HTMLDivElement>(null)
  const [selectedTemp, setSelectedTemp] = useState<Item | null>(null)

  useEffect(() => {
    if (items.length === 0) return
    setSelectedTemp(prev => (prev === null ? items[0] : prev))
  }, [items])

  useEffect(() => {
    if (modal.visible) {
      const index = items.findIndex(item => item.id === selected?.id)
      if (index < 0) return
      scrollRef.current?.scrollTo({ top: index * 38, left: 0 })
    }
  }, [modal.visible])

  const onScroll = (e: UIEvent) => {
    const scrollTop = (e.target as HTMLDivElement).scrollTop
    setSelectedTemp(items[Math.round(scrollTop / 38)])
  }

  return (
    <Modal id="scroll-selector-modal" containerStyle={container} zIndex={207}>
      <Active />
      {selectedTemp?.disabled && selectedTemp?.disabled_reason && (
        <Tooltip>
          <Icon
            id="triangle-left-2"
            width="10"
            height="14.5"
            className="arrow"
          />
          {selectedTemp.disabled_reason}
        </Tooltip>
      )}
      <ScrollList ref={scrollRef} onScroll={debounce(onScroll, 100)}>
        <Blank />
        <Blank />
        {items.map((item, index) => (
          <ScrollItem
            className={
              item.id === selectedTemp?.id && !selectedTemp?.disabled
                ? 'active'
                : ''
            }
            key={index}
            onClick={() =>
              scrollRef.current?.scrollTo({
                top: index * 38,
                left: 0,
                behavior: 'smooth',
              })
            }>
            {item.label}
          </ScrollItem>
        ))}
        <Blank />
        <Blank />
      </ScrollList>
      <div className="flx-btw-ctr">
        <Button onClick={() => closeModal(modal)} className="secondary">
          {t('cancel')}
        </Button>
        <Button
          disabled={!!selectedTemp?.disabled}
          onClick={async () => {
            selectedTemp && onOk(selectedTemp)
            await closeModal(modal)
          }}>
          {t('ok')}
        </Button>
      </div>
    </Modal>
  )
}

export default NiceModal.create(ScrollSelector)
