import { Route, lazy } from '@tanstack/router'

import { withdrawRoute } from '.'

const WithdrawForm = lazy(() => import('../../pages/withdraw/withdraw-form'))

export const withdrawFormRoute = new Route({
  getParentRoute: () => withdrawRoute,
  path: '/$id',
  component: WithdrawForm,
})
