import { lazy,Route } from '@tanstack/router'

import { Game } from '../types/game'
import { rootRoute } from './root'

const Game = lazy(() => import('../pages/game'))
  
export const gameRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'game',
  component: Game,
  validateSearch: (search: Record<string, unknown>): Game['gameInfo'] => {
    return search.gameInfo as Game['gameInfo']
  },
})
