import { useModal } from '@ebay/nice-modal-react'
import { type FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ToastMessage } from '../components/common'
import useGeoCheck from '../hooks/useGeoCheck'
// import {
//   addMaintenanceToken,
//   checkMaintenanceToken,
// } from '../utils/maintenance'
import { useBoundStore } from '../store/store'
import type { ApiResponse } from '../types/api'
import type { UserInfo } from '../types/user'
import { api } from '../utils/api-client'
// import closeModal from '../utils/closeModal'
import { getDeviceInfo } from '../utils/device'
import { getPublicAuth } from '../utils/getPublicAuth'
import { clearUser, decryptUser } from '../utils/user'

function withLogin(WrappedComponent: FC) {
  const WithLogin = (props: Record<string, unknown>) => {
    const { geoCheck } = useGeoCheck()
    const { i18n } = useTranslation()
    const { language } = i18n
    const setIsUserLoading = useBoundStore(state => state.user.setIsLoading)
    const setUser = useBoundStore(state => state.user.setUser)
    const toastMessage = useModal(ToastMessage)
    // const maintenanceModal = useModal(MaintenanceModal)
    // This state is used for getting the local storage after some time
    // When first load in safari local storage takes a bit of time to get populated
    const [delay, setDelay] = useState(true)

    useEffect(() => {
      setTimeout(() => setDelay(false), 1000)
    }, [])

    useEffect(() => {
      document.documentElement.lang = language
    }, [])

    useEffect(() => {
      if (delay) return
      const user = decryptUser()
      if (!user) {
        setIsUserLoading(false)
        return
      }

      const tryLogin = async () => {
        const publicAuth = await getPublicAuth()

        const reqBody = {
          ...user,
          submit_type: 'login',
          meta: getDeviceInfo(),
          auth: publicAuth,
        }
        const response = await api.post<ApiResponse<UserInfo>>(
          '/center.php',
          reqBody,
        )
        const { status, info } = response.data
        if (status === 1) {
          const privateAuth = await api.post<
            ApiResponse<string | { auth: string; expired_time: number }>
          >('/link.php', {
            username: info.username,
            password: user.password,
            auth: publicAuth,
          })
          if (
            privateAuth.data.status === 1 &&
            typeof privateAuth.data.info !== 'string'
          ) {
            localStorage.setItem('privateAuth', privateAuth.data.info.auth)
            setUser(info)
          } else {
            clearUser()
          }
        } else {
          clearUser()
        }
        setIsUserLoading(false)
      }

      geoCheck(tryLogin, () => {
        clearUser()
      })
    }, [delay])

    // useEffect(() => {
    //   // Check if web app is in maintenance
    //   const checkMaintenance = async () => {
    //     const response = await api.post<ApiResponse<string>>(
    //       '/ajax_datav2.php',
    //       {
    //         type: 'game_maintenance',
    //       },
    //     )
    //     const { status } = response.data
    //     const isTokenValid = checkMaintenanceToken()
    //     if (status === 0 && !isTokenValid) {
    //       // Force access by admin
    //       if (window.location.href.includes('admin_test')) {
    //         const password = window.prompt('Please enter admin password')
    //         if (password === 'secret') {
    //           addMaintenanceToken()
    //           closeModal(maintenanceModal)
    //         }
    //       }
    //     }
    //   }

    //   checkMaintenance()
    // }, [])

    useEffect(() => {
      const onOffline = () => {
        toastMessage.show({
          text:
            language === 'en'
              ? 'Please connect to the internet'
              : 'Por favor conectarse a Internet',
        })
      }

      window.addEventListener('offline', onOffline)
      return () => window.removeEventListener('offline', onOffline)
    }, [])

    return <WrappedComponent {...props} />
  }

  return WithLogin
}

export default withLogin
