import { Link } from '@tanstack/router'
import { styled } from '@linaria/react'
import useDimensions from '../../hooks/useDimensions'
import Icon from '../icon'

const Container = styled.div`
  background-color: var(--tertiary);
  padding: 0 16px;
  height: 60px;
  margin-bottom: 12px;
  transition: all 0.2s ease-in-out;
  svg {
    fill: var(--txt);
  }
  @media (min-width: 1200px) {
    height: 70px;
    margin-right: -1px;
    padding: 0 20px;
    &.active {
      .theme-dark & {
        background-color: #0e091e;
      }
      .theme-light & {
        background-color: #e0e0ec;
      }
    }
    .arrow {
      width: 12px;
      min-width: 12px;
    }
  }
`

const ChannelIcon = styled.div`
  width: 45px;
  display: flex;
  img {
    max-width: 25px;
    max-height: 25px;
  }
  @media (min-width: 1200px) {
    width: 50px;
    img {
      max-width: 30px;
      max-height: 30px;
    }
  }
`

const Label = styled.span`
  font-size: 14px;
  color: var(--txt);
  font-family: 'Avenir-Light';
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

const Note = styled.span`
  font-size: 12px;
  font-family: 'Avenir-Light';
  color: #999999;
  margin: 0 20px;
`

interface PlatformProps {
  link?: string
  label: string
  note?: string
  onClick?: () => void
  active?: boolean
  icon: string
  route: 'deposit' | 'withdraw'
}

const Platform = ({
  link,
  label,
  active,
  onClick,
  icon,
  note,
  route,
}: PlatformProps) => {
  const { isDesktop } = useDimensions()

  return (
    <Link
      to={!isDesktop ? `/${route}/$id` : undefined}
      params={{ id: link ?? '' }}>
      <Container
        className={'flx-btw-ctr ' + (active ? 'active' : '')}
        onClick={onClick}>
        <div className="flx-ctr">
          <ChannelIcon>
            <img src={icon} alt="Alipay" />
          </ChannelIcon>
          <Label>{label}</Label>
        </div>
        {note && <Note>{note}</Note>}
        <Icon id="chevron-right" width="10" />
      </Container>
    </Link>
  )
}

export default Platform
