import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'
import { AffiliateHomeSearch } from '../types/route-search'

const AffiliateHome = lazy(() => import('../pages/affiliate-home'))

export const affiliateHomeRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/affiliate/$affiliate',
  component: AffiliateHome,
  validateSearch: (search: Record<string, unknown>): AffiliateHomeSearch => {
    return search
  },
})
