import './i18n'
import 'blaze-slider/dist/blaze.css'

import Hotjar from '@hotjar/browser'
import { Suspense } from 'react'
import { hydrate, render } from 'react-dom'

import App from './App'
import { Loading } from './components/common'
import ErrorBoundary from './hoc/ErrorBoundary'

Hotjar.init(3539185, 6)

const Root = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </ErrorBoundary>
)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  if (rootElement?.hasChildNodes()) {
    hydrate(<Root />, rootElement)
  } else {
    render(<Root />, rootElement)
  }
}
